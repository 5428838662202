import {ChangeDetectionStrategy, Component, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import {LangService} from '@atl/shared/services';
import {TimeService} from "@atl/shared/services/time.service";
import {
    AUTH_SERVICE,
    AuthService,
    LocalStorageService,
    USER_SERVICE,
    UserService,
    WebsocketService
} from "@atl/lacerta-ui-common";
import {EventsService} from "@atl/main/alerts/services/events.service";
import {EventTypesService} from "@atl/admin/alerts/services";
import {RAISED_EVENTS_WEBSOCKET, RaisedEventsService} from "@atl/shared/services/raised-events.service";
import {NavigationEnd, Router} from "@angular/router";
import {filter, tap} from "rxjs/operators";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [EventsService, EventTypesService, RaisedEventsService, {
        provide: RAISED_EVENTS_WEBSOCKET,
        useClass: WebsocketService
    }]
})
export class AppComponent implements OnInit, OnDestroy {
    constructor(
        private lang: LangService,
        private storage: LocalStorageService,
        private timeService: TimeService,
        @Inject(USER_SERVICE) private userService: UserService,
        @Inject(AUTH_SERVICE) private auth: AuthService,
        private raisedEventsService: RaisedEventsService,
        private router: Router,
        private ngZone: NgZone,
    ) {
    }

    ngOnInit() {
        this.storage.initStorage();
        this.auth.initStorageListener();
        this.lang.initLang();
        this.timeService.initTime().subscribe()
        this.userService.requestCurrentUser().subscribe()

        this.userService.user$.subscribe(user => {
            if (user) {
                this.raisedEventsService.init()
            }
        })

        // Расчет ширины текста с использованием canvas
        // @ts-ignore
        Element.prototype.getTextWidth = function (text) {
            const canvas = document.createElement(`canvas`);
            const context = canvas.getContext(`2d`);
            context.font = `` + this.getAttribute(`font-size`) + `px ` + this.getAttribute(`font-family`);
            const {width} = context.measureText(text.toString());
            return width;
        };

        // В SVG при изменении контента tspan эелемент растягивается в правую сторону что не допустимо, требуется при
        // каждом изменении значения менять его координату X.
        // @ts-ignore
        SVGTextElement.prototype.setText = function (text, position: 'left' | 'right' | 'center' = 'right') {
            const textWidth = this.getTextWidth(text);
            this.firstChild.innerHTML = text.toString();
            let resultX
            const initialTextWidth = this.getAttribute('initial-text-width') ?? textWidth
            const textDiff = initialTextWidth - parseFloat(textWidth)
            if (position === 'right') {
                resultX = parseFloat(this.firstChild.getAttribute(`x-original`)) + textDiff;
            } else if (position === 'left') {
                resultX = parseFloat(this.firstChild.getAttribute(`x-original`));
            } else if (position === 'center') {
                resultX = parseFloat(this.firstChild.getAttribute(`x-original`)) + textDiff / 2;
            }
            this.firstChild.setAttribute(`x`, resultX);
        };

        // @ts-ignore
        SVGTextElement.prototype.setFloat = function (float: number, maxCharacters: number = 8, position: 'left' | 'right' | 'center' = 'right', digitsAfterPoint: number = 2) {
            let str = float.toFixed(digitsAfterPoint).toString();

            if (str.indexOf('.') === -1) {
                this.setText(float.toString(), position)
                return
            }

            let isNegative = false;
            if (str.charAt(0) === '-') {
                isNegative = true;
                str = str.substring(1);
            }

            let integer = str.split('.')[0];

            if (integer.length + (isNegative ? 1 : 0) >= maxCharacters - 1) {
                this.setText(Math.floor(float).toString(), position)
                return
            }

            let truncatedStr = str.substring(0, maxCharacters - (isNegative ? 1 : 0));

            if (isNegative) {
                truncatedStr = '-' + truncatedStr;
            }

            this.setText(truncatedStr, position)
        };

        // @ts-ignore
        SVGElement.prototype.hide = function () {
            this.style.opacity = 0;
        };

        // @ts-ignore
        SVGElement.prototype.show = function () {
            this.style.opacity = 1;
        };

        // @ts-ignore
        SVGElement.prototype.display = function (show) {
            this.style.opacity = show ? 1 : 0;
        };

        // @ts-ignore
        SVGElement.prototype.isVisible = function () {
            return !!this.style.opacity
        };

        // Electron
        window.addEventListener('message', (event) => {
            if (event.data && event.data.type === 'navigate') {
                this.ngZone.run(() => {
                    const sub = this.router.events.pipe(filter((event) => event instanceof NavigationEnd),).subscribe({
                        next: () => {
                            this.router.navigate([event.data.route]);
                            sub.unsubscribe()
                        },
                    })
                })
            }
        });
    }

    ngOnDestroy() {
        this.raisedEventsService.destroy()
    }
}
