import {IDType} from "@ali-hm/angular-tree-component/lib/defs/api";
import {Observable} from "rxjs";

export interface ILtaNodeData {
    id?: IDType,
    name: string,
    children?: ILtaNodeData[]
    hasChildren?: boolean //For async loading
    isExpanded?: boolean //For initial expansion
    hasAddItemBtn?: boolean
    item?: any
    type?: NodeType
    icon?: string
    description?: string
    selectable?: boolean
    disabled?: boolean
    theme?: 'link' | null
    useLayout?: LayoutType[]
}

export interface ILtaTreeOptions {
    mode?: TreeMode.SingleSelect | TreeMode.MultiSelect
    theme?: TreeTheme
    selectLimit?: number
    checkboxType?: TreeCheckboxType,
    selectAction?: (parent: ILtaNodeData) => void
    nameClickAction?: (parent: ILtaNodeData) => void
    addItemBtnOptions?: {
        text: string
        callback: (parent: ILtaNodeData) => void
    },
    sortFn?: (a, b) => number,
    filterFn?: (item, filterString: string) => boolean
    getChildrenFn?: GetChildrenFn
}

export enum TreeCheckboxType {
    round,
    text
}

export type TreeTheme = 'dark' | 'object-tree' | 'dark-object-tree'

export enum NodeType {
    DEFAULT,
    ADD
}

export enum LayoutType {
    DriverMappingLayout = 1,
    ObjectSettingsLayout = 2,
    ObjectDescriptionLayout = 3,
    ObjectMnemoLayout = 4,
    SelectionLayout = 5,
    ObjectLinkLayout = 6,
}

export enum TreeMode {
    SingleSelect = 1,
    MultiSelect = 2,
}


export type GetChildrenFn = (item: ILtaNodeData) => Observable<ILtaNodeData[]>
