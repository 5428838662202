import {Injectable} from '@angular/core'
import {UntilDestroy} from '@ngneat/until-destroy'
import {BehaviorSubject, forkJoin, Observable, of} from 'rxjs'
import {map, tap} from 'rxjs/operators'
import {INewPasswordForm, IUser, UserFilter} from '../interfaces'
import {UsersHttpService} from './users-http.service'
import {IUserToken} from '@app/@atl/modules/modals/token-dialog/token-dialog.component'
import {sortByField} from "@atl/shared/utils";

@UntilDestroy()
@Injectable()
export class UsersService {
    public usersMap: Map<number, IUser> = new Map<number, IUser>()
    private usersSubject: BehaviorSubject<IUser[]> = new BehaviorSubject<IUser[]>([])
    public users$: Observable<IUser[]> = this.usersSubject.asObservable().pipe(map(users => sortByField<IUser, string>(users, user => user.name)))
    private userPicturesSubject: BehaviorSubject<Map<number, string | null>> = new BehaviorSubject<Map<number, string | null>>(new Map<number, string | null>())
    public userPictures$: Observable<Map<number, string | null>> = this.userPicturesSubject.asObservable()
    private activeUserSubject: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null)
    public activeUser$: Observable<IUser> = this.activeUserSubject.asObservable()
    private userTokensSubject: BehaviorSubject<IUserToken[]> = new BehaviorSubject<IUserToken[]>([])
    public userTokens$: Observable<IUserToken[]> = this.userTokensSubject.asObservable()

    constructor(private usersHttp: UsersHttpService) {
    }

    public setActiveUser(user: IUser | null) {
        this.activeUserSubject.next(user)
    }

    public getUserById(userId: number): Observable<IUser> {
        return this.usersHttp.getUserById(userId).pipe(tap(user => {
            this.usersSubject.next(this.usersSubject.value.map(value => value.id === user.id ? user : value))
        }))
    }

    public getAllUsers(filter?: UserFilter): Observable<IUser[]> {
        return this.usersHttp
            .getAllUsers(filter)
            .pipe(
                tap(users => {
                    this.usersSubject.next(users)
                    users.forEach(value => {
                        this.usersMap.set(value.id, value)
                    })
                })
            )
    }

    public toggleUserStatus(user: IUser) {
        const req: Observable<string> = user.active ? this.usersHttp.deactivateUser(user.id) : this.usersHttp.activateUser(user.id);

        return req.pipe(tap(() => {
            this.usersSubject.next(this.usersSubject.value.map(v => v.id === user.id ? {
                ...user,
                active: !user.active
            } : v))

            this.activeUserSubject.next({...user, active: !user.active})
        }))
    }

    public getUserPictures(users: IUser[]): Observable<Map<number, string | null>> {
        return forkJoin([of(users), forkJoin(users.map(user => user.avatar_id ? this.usersHttp.getUserPictureById(user.id).pipe(map(value => value.avatar)) : of(null)))])
            .pipe(
                map(([users, pictures]: [IUser[], (string | null)[]]) => {
                    const usersPicturesMap = new Map<number, string | null>()
                    users.forEach((user, i) => usersPicturesMap.set(user.id, pictures[i]))
                    return usersPicturesMap
                }),
                tap(userPicturesMap => this.userPicturesSubject.next(userPicturesMap))
            )
    }

    public addBlankUser() {
        this.activeUserSubject.next({
            id: null,
            name: '',
            login: '',
            email: '',
            password: '',
            role: null,
            role_id: null,
            tm_create: null,
            tm_update: null,
            active: null,
            fountain: null,
        })
    }

    public createUser(user: IUser): Observable<IUser> {
        return this.usersHttp.createUser(user)
            .pipe(
                tap(user => {
                    this.activeUserSubject.next(user)
                    this.usersSubject.next([...this.usersSubject.value, user])
                })
            )
    }

    public updateUser(user: IUser): Observable<IUser> {
        return this.usersHttp.updateUser(user)
            .pipe(
                tap(user => {
                    this.activeUserSubject.next(user)
                    this.usersSubject.next(this.usersSubject.value.map(v => v.id === user.id ? user : v))
                })
            )
    }

    public deleteUser(user: IUser): Observable<null> {
        return this.usersHttp.deleteUser(user)
            .pipe(
                tap(() => {
                    this.activeUserSubject.next(null)
                    this.usersSubject.next(this.usersSubject.value.filter(v => v.id !== user.id))
                })
            )
    }

    public editPassword(passwords: INewPasswordForm, user: IUser): Observable<string> {
        return this.usersHttp.editPassword(user.id, passwords)
    }

    public forceEditPassword(passwords: Omit<INewPasswordForm, 'password_old'>, user: IUser): Observable<string> {
        return this.usersHttp.forceEditPassword(user.id, passwords)
    }

    public updateUserPicture(user: IUser, picture: string): Observable<string> {
        return this.usersHttp.setUserPictureById(user.id, picture)
            .pipe(
                tap(() => {
                    this.userPicturesSubject.next(this.userPicturesSubject.value.set(user.id, picture))
                }),
                map(() => 'picture updated')
            )
    }

    public deleteUserPicture(user: IUser): Observable<string> {
        return this.usersHttp.deleteUserPictureById(user.id)
            .pipe(
                tap(() => {
                    this.userPicturesSubject.next(this.userPicturesSubject.value.set(user.id, null))
                }),
                map(() => 'picture deleted')
            )
    }

    public createUserToken(userToken: IUserToken, id: number) {
        let userTokenPermissionsIds = userToken.permissions.map((permission) => permission.id)
        let newUserTokenData = {
            expiration: Number(userToken.expiration),
            permissions: userTokenPermissionsIds
        }
        return this.usersHttp.createUserToken(newUserTokenData, id).pipe(
            tap((token) => {
                    this.userTokensSubject.next([...this.userTokensSubject.value, token])
                }
            ))
    }

    public editUserToken(userToken: IUserToken, id: number) {
        let userTokenPermissionsIds = userToken.permissions.map((permission) => permission.id)
        let newUserTokenData = {
            expiration: Number(userToken.expiration),
            permissions: userTokenPermissionsIds
        }
        return this.usersHttp.editUserToken(newUserTokenData, id, userToken.user_token_id)
    }

    public deleteUserToken(userToken: IUserToken, id: number) {
        return this.usersHttp.deleteUserTokenById(id, userToken.user_token_id)
            .pipe(
                tap(() => {
                        this.userTokensSubject.next(this.userTokensSubject.value.filter((token) => token.user_token_id !== userToken.user_token_id))
                    }
                ))
    }

    public getUserTokens(id: number) {
        return this.usersHttp.getUserTokens(id)
            .pipe(
                map((tokens) => tokens.map(token => {
                    if (Date.now() * 1000000 > token.expiration) {
                        token.isExpirated = true
                    } else {
                        token.isExpirated = false
                    }
                    return token
                })),
                tap((tokens) => this.userTokensSubject.next(tokens))
            )
    }

    public getUserTokenById(id: number, tokenId: number) {
        return this.usersHttp.getUserTokenById(id, tokenId)
    }
}
