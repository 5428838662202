import {Inject, Injectable} from "@angular/core";
import {EventWebsocketMessage, IEvent, IEventType, WebsocketService} from "@atl/lacerta-ui-common";
import {BehaviorSubject, Observable} from "rxjs";
import {EventsService} from "@atl/main/alerts/services/events.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {EventTypesService} from "@atl/admin/alerts/services";
import {map, tap} from "rxjs/operators";

export const RAISED_EVENTS_WEBSOCKET = 'ACTIVE_EVENTS_WEBSOCKET'

@UntilDestroy()
@Injectable()
export class RaisedEventsService {
    public uncheckedEventsCount$ = new BehaviorSubject<string>(null)
    public lastEventColor$ = new BehaviorSubject<string>(null)
    public hasRaisedEvents$ = new BehaviorSubject<boolean>(false)
    public raisedEvents$ = new BehaviorSubject<(IEvent & { event_class?: IEventType })[]>([])
    public uncheckedEvents$ = new BehaviorSubject<(IEvent & { event_class?: IEventType })[]>([])
    public soundEventSubject = new BehaviorSubject<(IEvent & { event_class?: IEventType })>(null)
    public soundEvent$ = this.soundEventSubject.asObservable().pipe(tap(ev => {
        if (ev) {
            this.playSound(ev.event_class.event_class_sound.id)
        } else {
            this.stopSound()
        }
    }))
    private events$: Observable<EventWebsocketMessage>
    private unsubscribeFn: () => void
    private lastAudio: { interval: NodeJS.Timeout, audio: HTMLAudioElement, soundId: number } = null
    private audioInProgress = false

    constructor(@Inject(RAISED_EVENTS_WEBSOCKET) private websocketService: WebsocketService, private eventsService: EventsService, private eventTypesService: EventTypesService) {
    }

    public destroy() {
        if (this.unsubscribeFn) {
            this.unsubscribeFn()
        }
    }

    public subscribeToObjectEvents(objectId: number) {
        return this.raisedEvents$.pipe(map(events => events.filter(ev => objectId ? ev.object_path.some(p => p.id === objectId) : true)))
    }

    public init() {
        this.eventTypesService.getEventTypes().subscribe(() => {
            const [events$, unsubscribeFn] = this.websocketService.subscribeToEvents({});
            this.events$ = events$
            this.unsubscribeFn = unsubscribeFn
            this.eventsService.isSubscribed = true
            this.events$.pipe(untilDestroyed(this)).subscribe(ev => this.eventsService.handleEventSignal(ev))

            this.eventsService.addEventsFilters({
                limit: 99999,
                dataType: 'raised'
            })


            this.eventsService.getEvents(true)
        })

        this.eventsService.events$.pipe(untilDestroyed(this)).subscribe(events => {
            this.raisedEvents$.next(events)
            this.uncheckedEvents$.next(events.filter(value => value.event_class?.can_check).filter(e => !EventsService.isEventChecked(e)))
            const uncheckedEvents = this.uncheckedEvents$.value

            this.uncheckedEventsCount$.next(uncheckedEvents.length ? uncheckedEvents.length > 99 ? '99+' : uncheckedEvents.length.toString() : '')
            this.lastEventColor$.next(
                uncheckedEvents.length ?
                    uncheckedEvents[0]?.event_class?.color_background :
                    events[0]?.event_class?.color_background_checked
            )
            const eventsWithSound = uncheckedEvents.filter(e => !!e.event_class?.event_class_sound)
            const sortByPriority = [...eventsWithSound].sort((a, b) => a.event_class.priority - b.event_class.priority)
            this.soundEventSubject.next(
                sortByPriority.length ?
                    sortByPriority[0] :
                    null
            )
            this.hasRaisedEvents$.next(events.length > 0)
        })

        this.eventTypesService.getEventClassSounds().subscribe()

        this.soundEvent$.pipe(untilDestroyed(this)).subscribe()
    }

    private stopSound() {
        if (!this.lastAudio) return
        clearInterval(this.lastAudio.interval)
        this.lastAudio.audio.remove()
        this.lastAudio = null
    }

    private playSound(soundId: number) {
        if (this.audioInProgress) return;
        const sound = this.eventTypesService.getSoundById(soundId)
        if (this.lastAudio?.soundId === soundId) return

        this.stopSound()

        if (!sound) return

        const audio = new Audio();
        audio.src = sound;
        audio.load();

        this.audioInProgress = true
        audio.oncanplaythrough = () => {
            audio.play().catch(() => null)

            this.lastAudio = {
                interval: setInterval(() => {
                    audio.play().catch(() => null)
                }, audio.duration * 1000 + 1000),
                soundId,
                audio
            }
            audio.oncanplaythrough = null
            this.audioInProgress = false
        };


    }
}
