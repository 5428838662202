<tree-root
        #tree
        (loadNodeChildren)="applyFilterToChild($event.node)"
        [(state)]="state"
        [hidden]="!nodes?.length"
        [nodes]="nodes"
        [options]="_options"
>
    <ng-template
            #treeNodeFullTemplate
            let-index="index"
            let-node
            let-templates="templates"
    >
        <div
                [class.tree-node-active]="customOptions?.selectAction ? node.isActive : false"
                [class.tree-node-collapsed]="node.isCollapsed && node.hasChildren"
                [class.tree-node-expanded]="node.isExpanded && node.hasChildren"
                [class.tree-node-focused]="node.isFocused"
                [class.tree-node-leaf]="node.isLeaf"
                [class.tree-node-with-checkbox]="isMultiSelectMode && getNodeData(node).selectable"
                [class.tree-node]="true"
                [class]="node.getClass()"
                [ngClass]="customOptions?.theme"
        >

            <tree-node-drop-slot
                    *ngIf="index === 0"
                    [dropIndex]="node.index"
                    [node]="node.parent"
            >
            </tree-node-drop-slot>

            <ng-container *ngTemplateOutlet="ltaNode; context: {$implicit: node, index};"></ng-container>

            <tree-node-children
                    [node]="node"
                    [templates]="templates"
            >
            </tree-node-children>
            <tree-node-drop-slot
                    [dropIndex]="node.index + 1"
                    [node]="node.parent"
            >
            </tree-node-drop-slot>
        </div>
    </ng-template>

    <ng-template #loadingTemplate>
        <div class="shimmer"></div>
    </ng-template>
</tree-root>

<ng-template
        #ltaNode
        let-index="index"
        let-node
>
    <div
            [class.add-item-btn]="getNodeData(node).type === NodeType.ADD"
            [class.disabled]="getNodeData(node).disabled"
            [class.node-content-wrapper-active]="node.isActive"
            [class.node-content-wrapper-expanded]="node.isExpanded"
            [class.node-content-wrapper-focused]="node.isFocused"
            [style.padding-left]="node.getNodePadding()"
            class="node-wrapper"
    >
        <span
                *ngIf="node.hasChildren && showChevron"
                class="toggler padding-offset"
        >
            <lta-svg-icon
                    (click)="$event.stopImmediatePropagation(); node.expand(); ensureChildrenVisibility(node)"
                    *ngIf="!node.isExpanded"
                    icon="chevron-right"
                    size="20"
            ></lta-svg-icon>
            <lta-svg-icon
                    (click)="$event.stopImmediatePropagation(); node.collapse()"
                    *ngIf="node.isExpanded"
                    icon="chevron-down"
                    size="20"
            ></lta-svg-icon>
        </span>
        <div
                (click)="$event.stopImmediatePropagation(); isNodeSelectable(node, isMultiSelectMode) && node.mouseAction('click', $event)"
                (contextmenu)="node.mouseAction('contextMenu', $event)"
                (dblclick)="node.mouseAction('dblClick', $event)"
                (mouseenter)="isNodeSelectable(node, isMultiSelectMode) && onHover.emit(node)"
                (mouseleave)="isNodeSelectable(node, isMultiSelectMode) && onHover.emit(null)"
                (treeDrop)="node.onDrop($event)"
                [class.padding-offset]="!node.hasChildren"
                [class.pl-10]="!showChevron"
                [treeAllowDrop]="node.allowDrop"
                [treeDragEnabled]="node.allowDrag()"
                [treeDrag]="node"
                class="node-content"
        >
            <lta-svg-icon
                    *ngIf="getNodeData(node).icon"
                    [icon]="getNodeData(node).icon"
                    class="node-content__icon"
                    size="20"
            ></lta-svg-icon>
            <div
                    [class.text-ellipsis]="cropText"
                    class="node-content__name"
            >
                <span
                        (click)="customOptions?.nameClickAction && customOptions?.nameClickAction(getNodeData(node))"
                        class="item__title"
                >{{ getNodeData(node).name }}</span>
                <span
                        *ngIf="getNodeData(node).description"
                        class="node-content__description"
                >{{ getNodeData(node).description }}</span>
                <ng-container *ngIf="getNodeData(node).useLayout && getNodeData(node).useLayout.includes(LayoutType.ObjectDescriptionLayout)">
                    <ng-container *ngTemplateOutlet="objectDescription; context: getNodeData(node)"></ng-container>
                </ng-container>

            </div>
            <ng-container *ngIf="getNodeData(node).useLayout && getNodeData(node).useLayout.length">
                <div
                        class="node-content__layout"
                >
                    <ng-container *ngFor="let layout of getNodeData(node).useLayout">
                        <ng-container [ngSwitch]="layout">
                            <ng-container *ngSwitchCase="LayoutType.DriverMappingLayout">
                                <ng-container *ngTemplateOutlet="driverMapping; context: getNodeData(node)"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="LayoutType.ObjectSettingsLayout">
                                <ng-container *ngTemplateOutlet="objectSettings; context: getNodeData(node)"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="LayoutType.ObjectMnemoLayout">
                                <ng-container *ngTemplateOutlet="objectMnemo; context: getNodeData(node)"></ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="getNodeData(node).useLayout && getNodeData(node).useLayout.length">
                <div
                        class="node-content__layout ml-auto"
                >
                    <ng-container *ngFor="let layout of getNodeData(node).useLayout">
                        <ng-container [ngSwitch]="layout">
                            <ng-container *ngSwitchCase="LayoutType.SelectionLayout">
                                <ng-container *ngTemplateOutlet="selection; context: getNodeData(node)"></ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
            <div
                    *ngIf="isMultiSelectMode && isNodeSelectable(node, isMultiSelectMode)"
                    class="node-content__select"
            >
                <ng-container
                        *ngTemplateOutlet="
                          customOptions?.checkboxType === TreeCheckboxType.round ?
                          selectRound : selectText;
                          context: {selected: ignoreSelect ? false : isNodeSelected(node.id), parentSelected: node.parent && isAnyParentSelected(node), someChildrenSelected: node.children && isSomeNodesSelected(node.children)};"
                ></ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template
        #objectDescription
        let-item="item"
>

    <span
            *ngIf="item.descr"
            class="node-content__description obj-description"
    >{{ item.descr }}</span>
</ng-template>

<ng-template
        #driverMapping
        let-mapping="item.mapping"
>

    <lta-tree-mapping-item
            *ngFor="let map of mapping"
            [mapping]="map"
    ></lta-tree-mapping-item>
</ng-template>

<ng-template
        #objectSettings
        let-item="item"
>
    <lta-tree-settings-item
            [allowed]="item.driver_settings?.enabled"
            [item]="item"
            [lta-tooltip]="item.driver_settings?.settings?.name"
            position="top"
            width="auto"
    ></lta-tree-settings-item>
</ng-template>

<ng-template
        #objectMnemo
        let-item="item"
>
    <lta-tree-object-mnemo-item
            [item]="item"
            [withArguments]="withArgumentsMnemo"
    ></lta-tree-object-mnemo-item>
</ng-template>
<ng-template
        #selection
        let-item="item"
>
    <lta-tree-selection-item
            [selection]="item"
    ></lta-tree-selection-item>
</ng-template>

<ng-template
        #selectRound
        let-parentSelected="parentSelected"
        let-selected="selected"
        let-someChildrenSelected="someChildrenSelected"
>
    <lta-svg-icon
            *ngIf="selected || parentSelected;"
            class="select__icon"
            icon="selected"
            size="20"
    ></lta-svg-icon>
    <lta-svg-icon
            *ngIf="!(selected || parentSelected) && !someChildrenSelected && !selected"
            class="select__icon"
            icon="unselected"
            size="20"
    ></lta-svg-icon>
    <lta-svg-icon
            *ngIf="!(selected || parentSelected) && someChildrenSelected"
            class="select__icon"
            icon="checkbox-circle-some"
            size="20"
    ></lta-svg-icon>
</ng-template>

<ng-template
        #selectText
        let-selected="selected"
>
    <div
            *ngIf="selected; else elseNotSelected"
            class="select__text--selected"
    >
                    <span
                    >{{ 'common.selected' | translate }}</span>
    </div>
    <ng-template #elseNotSelected>
        <div
                class="select__text"
        >
                    <span
                    >{{ 'common.select' | translate }}</span>
        </div>
    </ng-template>
</ng-template>
