import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {
    ILtaNodeData,
    ILtaTreeOptions,
    LayoutType,
    NodeType,
    TreeCheckboxType,
    TreeMode
} from "@atl/modules/tree/interfaces/tree.interface";
import {ITreeOptions, ITreeState, TreeComponent, TreeModel, TreeNode} from "@ali-hm/angular-tree-component";
import {distinctUntilChanged, map} from "rxjs/operators";
import {IDType} from "@ali-hm/angular-tree-component/lib/defs/api";
import {BehaviorSubject} from "rxjs";
import {isEqual, remove} from "lodash";

@UntilDestroy()
@Component({
    selector: 'lta-tree',
    templateUrl: 'tree.component.html',
    styleUrls: ['tree.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LtaTreeComponent {
    public static addItemBtnPrefix = 'AddItem-'
    @ViewChild('tree') tree: TreeComponent;
    public customOptions: ILtaTreeOptions
    public NodeType = NodeType
    public LayoutType = LayoutType;
    public TreeCheckboxType = TreeCheckboxType;
    @Output() onHover = new EventEmitter<TreeNode>()
    @Input() cropText = true
    @Input() hasTitle = true
    @Input() showChevron = true
    @Input() withArgumentsMnemo = true
    public onLoadChildren: EventEmitter<number> = new EventEmitter<number>();
    private activeNodeSubject = new BehaviorSubject<ILtaNodeData[]>([])
    public activeNode$ = this.activeNodeSubject.asObservable()
        .pipe(map(selected => selected.length ? selected[0] : null))
        .pipe(distinctUntilChanged((x, y) => isEqual(x?.id, y?.id)))
    private selectedIDsSubject = new BehaviorSubject<IDType[]>([])
    public selectedIDs$ = this.selectedIDsSubject.asObservable()
        .pipe(distinctUntilChanged((x, y) => isEqual(x, y)))

    constructor(private cd: ChangeDetectorRef, private ref: ElementRef) {
    }

    private _filterString: string

    @Input() set filterString(string: string) {
        this._filterString = string
        if (!this.nodes?.length) return
        if (!string && this.state) {
            this.resetSearchState()
            return
        }
        this.setFilter(this._filterString)
        this.cd.markForCheck()
    }

    public get ignoreSelect() {
        return !!this.customOptions.selectAction
    }

    private _nodes: ILtaNodeData[]

    public get nodes() {
        return this._nodes
    }

    public set nodes(nodes: ILtaNodeData[]) {
        this._nodes = nodes
        this.state = this.state ? {...this.state} : {}
        this.tree.treeModel.update()
        this.cd.markForCheck()
    }

    private _state: ITreeState

    public get state() {
        return this._state
    }

    public set state(state: ITreeState) {
        this._state = {...state}
        this.activeNodeSubject.next(Object.keys(state.activeNodeIds || {}).map(id => this.getNodeById(id)?.data))
        this.selectedIDsSubject.next(Object.keys(state.selectedLeafNodeIds || {}))
        this.cd.markForCheck()
    }

    public get isMultiSelectMode() {
        return this.customOptions?.mode === TreeMode.MultiSelect
    }

    public _options: ITreeOptions

    @Input() set options(options: ILtaTreeOptions) {
        this._options = {
            getChildren: (node: TreeNode) => {
                const data = node.data as ILtaNodeData
                return this.customOptions.getChildrenFn(data).pipe(map(nodes => {
                    if (options.sortFn) nodes.sort((n1, n2) => options.sortFn(n1.item, n2.item))
                    if (data.hasAddItemBtn) {
                        nodes.push({
                            id: `${LtaTreeComponent.addItemBtnPrefix}${node.id}`,
                            name: this.customOptions.addItemBtnOptions.text,
                            icon: 'plus',
                            type: NodeType.ADD
                        })
                    }
                    if (!nodes.length) {
                        node.collapse()
                        node.data.hasChildren = false
                    }
                    this.onLoadChildren.emit(node.id);
                    if (this.isNodeSelected(node.id)) {
                        setTimeout(() => {
                            this.removeSelected(nodes.map(n => n.id))
                            this.resetFocus()
                        })
                    }

                    return nodes
                })).toPromise();
            },
            actionMapping: {
                mouse: {
                    click: (tree: TreeModel, node: TreeNode, $event: Event) => {
                        this.onClickNode(tree, node, $event)
                    },

                }
            },
            scrollOnActivate: false

        }

        this.customOptions = options
    }

    public get selectedNumber() {
        return Object.keys(this.state.selectedLeafNodeIds).length
    }

    private get selectMode(): TreeMode {
        return this.customOptions?.mode === undefined ? TreeMode.SingleSelect : this.customOptions.mode
    }

    public setFilter(string: string) {
        this.tree?.treeModel.filterNodes((node: TreeNode) => {
            const filterNode = (node: TreeNode) => this.customOptions?.filterFn ? this.customOptions.filterFn(node.data.item, string) : this.filter(node.data, string)
            if (node.data.type === NodeType.ADD) {
                return node.parent && filterNode(node.parent);

            }
            const result = filterNode(node);
            setTimeout(() => {
                if (result) {
                    setTimeout(() => this.ensureChildrenVisibility(node))
                }
            })
            return result;
        }, true);
    }

    public isNodeSelected(nodeId: IDType): boolean {
        return Object.keys(this.state.selectedLeafNodeIds).includes(nodeId.toString())
    }

    public isNodesSelected(nodeId: IDType[]): boolean {
        return nodeId.every(id => this.isNodeSelected(id))
    }

    public isNodeSelectable(node: TreeNode, isMultiSelectMode: boolean) {
        const data: ILtaNodeData = node.data
        if (isMultiSelectMode) {
            return data.selectable === undefined || node.isActive ? false : data.selectable

        } else {
            return data.selectable === undefined ? true : data.selectable
        }
    }

    public resetSearchState() {
        const hiddenNodeIds = {}
        const expandedNodeIds = {}
        this.state = {...this.state, hiddenNodeIds, expandedNodeIds}
    }

    public isSelected(item: ILtaNodeData) {
        return this.state.activeNodeIds[item.id]
    }

    public resetActive() {
        const activeNodeIds = {}
        this.state = {...this.state, activeNodeIds}
    }

    public setActive(id: IDType) {
        const activeNodeIds = {[id]: true}
        this.state = {...this.state, activeNodeIds}
    }

    public setSelected(ids: IDType[]) {
        const selectedLeafNodeIds = {}
        ids.forEach(id => {
            selectedLeafNodeIds [id] = true
        })
        this.state = {...this.state, selectedLeafNodeIds}
    }

    public addSelected(ids: IDType[]) {
        const allIds = [...Object.keys(this.state.selectedLeafNodeIds), ...ids]
        const selectedLeafNodeIds = {}
        allIds.forEach(id => {
            selectedLeafNodeIds [id] = true
        })
        this.state = {...this.state, selectedLeafNodeIds}
    }

    public removeSelected(ids: IDType[]) {
        const allIds = Object.keys(this.state.selectedLeafNodeIds).filter(id => !ids.map(id => id.toString()).includes(id))
        const selectedLeafNodeIds = {}
        allIds.forEach(id => {
            selectedLeafNodeIds[id] = false
        })
        this.state = {...this.state, selectedLeafNodeIds}
    }

    public setFocus(id: IDType) {
        if (!id) return;

        // node?.parent?.expandAll()
        const focusedNodeId = id

        this.state = {...this.state, focusedNodeId}
        this.setScrollPositionById(id)
    }

    public setScrollPositionById(id: IDType) {
        const node = this.getNodeById(id)
        if (node) {
            setTimeout(() => {
                this.scrollIntoView(node)
            }, 100)
        } else {
            this.waitForNodeCreation(id, (node) => {
                setTimeout(() => {
                    this.scrollIntoView(node)
                }, 500)
            })
        }
    }

    public resetFocus() {
        const focusedNodeId = null
        this.state = {...this.state, focusedNodeId}
    }

    public scrollIntoView(node: TreeNode) {
        this.tree.treeModel.virtualScroll.scrollIntoView(node, true, false)
        // node.scrollIntoView(true)
    }

    public expandNodes(ids: IDType[], set = false) {
        const allIds = this.state.expandedNodeIds ? [...(set ? [] : Object.keys(this.state.expandedNodeIds).filter(key => this.state.expandedNodeIds[key])), ...ids] : [...ids]
        const expandedNodeIds = {}
        allIds.forEach(i => {
            expandedNodeIds[i] = true
        })
        this.state = {...this.state, expandedNodeIds}
    }

    public collapseNodes(ids: IDType[]) {
        const expandedIds = Object.keys(this.state.expandedNodeIds).filter(key => this.state.expandedNodeIds[key])
        const collapsedIds = ids
        const expandedNodeIds = {}
        expandedIds.forEach(i => {
            expandedNodeIds[i] = true
        })
        collapsedIds.forEach(i => {
            expandedNodeIds[i] = false
        })
        this.state = {...this.state, expandedNodeIds}
    }

    public getNodeById(id: IDType): TreeNode {
        return this.tree.treeModel.getNodeById(id)
    }

    public getNodeData(node: TreeNode) {
        return node.data as ILtaNodeData
    }

    public addNode(node: ILtaNodeData, parentNode?: TreeNode) {
        if (parentNode) {
            parentNode.data.children = this.sortNodes([...(parentNode.data?.children || []), node])
            this.cd.markForCheck()
        } else {
            this.nodes = this.sortNodes([...this.nodes, node])
        }

        this.tree.treeModel.update();
        setTimeout(() => this.scrollIntoView(this.getNodeById(node.id)))
    }

    public updateNode(data: ILtaNodeData) {
        const node = this.getNodeById(data.id)
        if (!node) return
        node.data.item = data.item;
        node.data.name = data.name;
        node.data.icon = data.icon;
        node.data.description = data.description;
        node.data.useLayout = data.useLayout;
        node.data.hasChildren = data.hasChildren;
        if (data.children) this.setChildren(node, data.children)
        if (data.children === null) {
            node.collapse()
            node.data.children = null
            node.children = null

        }

        const parent = node.parent
        if (parent.data.virtual) {
            this.nodes = this.sortNodes([...this.nodes])
        } else {
            parent.data.children = this.sortNodes([...(parent.data?.children || [])])
        }
        this.cd.markForCheck()
        this.tree.treeModel.update();
    }

    public deleteNode(node: TreeNode) {
        const parentNode = node.realParent ? node.realParent : node.treeModel.virtualRoot;
        remove(parentNode.data.children, function (child) {
            return child === node.data;
        });
        node.treeModel.update();
        setTimeout(() => {
            this.scrollIntoView(parentNode)
        })


        if (this.state.activeNodeIds[node.data.id]) {
            this.state.activeNodeIds[node.data.id] = false
        }

        this.cd.markForCheck()
    }

    public ensureChildrenVisibility(node: TreeNode) {
        if (!node.children) return
        const children = node.children.filter(node => this.getNodeData(node).type !== NodeType.ADD)
        if (!children.some(node => !node.isHidden)) {
            node.children.forEach(node => {
                node.show()
                node.ensureVisible()
            })
        }
    }

    applyFilterToChild(node: TreeNode) {
        if ((!node.isHidden) || !this._filterString) return
        this.setFilter(this._filterString)
    }

    checkTreeHeight() {
        let elements = this.ref.nativeElement.querySelectorAll('tree-node')
        let elementsContainer = this.ref.nativeElement.querySelector('.tree-children')

        if (elementsContainer && elements && elements.length) {
            let lastEl = elements[elements.length - 1]
            let lastElHeight = lastEl.offsetHeight
            if (lastElHeight > 33) {
                elementsContainer.classList.add('short-line')
            }
        }
    }

    public isSomeNodesSelected(nodes: TreeNode[]) {
        return nodes.some((c) => this.isNodeSelected(c.id));
    }

    public isAnyParentSelected(node: TreeNode) {
        let parent = node.parent
        while (parent) {
            if (this.isNodeSelected(parent.id)) return true
            parent = parent.parent
        }
        return false
    }

    private getAllNodeParents(node: TreeNode) {
        const parents: TreeNode[] = []
        let currentParent = node.parent
        while (currentParent) {
            if (currentParent.data.virtual) break
            parents.push(currentParent)
            currentParent = currentParent.parent
        }
        return parents
    }

    private waitForNodeCreation(id: IDType, cb: (node: TreeNode) => void) {
        const node = this.getNodeById(id)
        if (!node) {
            setTimeout(() => {
                this.waitForNodeCreation(id, cb)
            }, 100)
        } else {
            cb(node)
        }
    }

    private filter(item: ILtaNodeData, filterString: string): boolean {
        const name = item.name
        const description = item.description || ''
        return name.toLowerCase().indexOf(filterString) !== -1 || description.toLowerCase().indexOf(filterString) !== -1
    }

    private setChildren(node: TreeNode, children: ILtaNodeData[]) {
        const data: ILtaNodeData = node.data
        const addItem = remove(data.children, (node) => {
            return node.type === NodeType.ADD
        })

        data.children = [...children, ...addItem]
    }

    private sortNodes(nodes: ILtaNodeData[]): ILtaNodeData[] {
        const addItem = remove(nodes, (node) => {
            return node.type === NodeType.ADD
        })
        return [...(this.customOptions.sortFn ? nodes.sort((node1, node2) => {
            return this.customOptions.sortFn(node1.item, node2.item)
        }) : nodes.sort((a, b) => {
            const textA = a.name.toUpperCase();
            const textB = b.name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })), ...addItem]
    }

    private onClickNode(tree: TreeModel, node: TreeNode, $event: Event) {
        const data = node.data as ILtaNodeData
        if (data.type === NodeType.ADD) {
            this.customOptions.addItemBtnOptions.callback(node.parent.data)
            this.setActive(node.id)
            return
        }

        if (this.customOptions?.selectLimit && this.selectedNumber >= this.customOptions?.selectLimit) return;

        if (this.customOptions.selectAction) {
            this.customOptions.selectAction(node.data)
        } else {
            if (this.selectMode === TreeMode.SingleSelect) {
                this.setActive(node.id)
                this.resetFocus()
            } else if (this.selectMode === TreeMode.MultiSelect) {
                if (this.isNodeSelected(node.id)) {
                    // Deselect the current node if already selected
                    this.removeSelected([node.id]);
                } else {
                    // Handle sibling selection
                    const siblings = node.parent.children.map(c => c.id).filter(id => id !== node.id);
                    if (this.getNodeData(node.parent).selectable && this.isNodesSelected(siblings)) {
                        // Deselect siblings and select the parent
                        this.removeSelected(siblings);
                        this.addSelected([node.parent.id]);
                        return;
                    }
                    if (this.isAnyParentSelected(node)) {
                        const allParents = this.getAllNodeParents(node).reverse()
                        allParents.forEach(p => {
                            if (this.isNodeSelected(p.id)) {
                                this.removeSelected([p.id])
                                this.addSelected(p.children.map(c => c.id))
                            }

                        })
                        this.removeSelected([node.id])
                        return;
                    }
                    // Select the current node
                    this.addSelected([node.id]);
                    // Deselect all children of the current node
                    if (node.children) {
                        function getLeafNodes(nodes, result = []) {
                            for (var i = 0, length = nodes.length; i < length; i++) {
                                if (!nodes[i].children || nodes[i].children.length === 0) {
                                    result.push(nodes[i]);
                                } else {
                                    result.push(nodes[i]);
                                    result = getLeafNodes(nodes[i].children, result);
                                }
                            }
                            return result;
                        }

                        const toRemove: TreeNode[] = getLeafNodes(node.children)
                        this.removeSelected(toRemove.map(n => n.id))
                    }

                }
            }
        }
    }
}

