import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {ObjectsService} from '@atl/admin/objects/services';
import {EventTypesService} from '@app/@atl/administration/alerts/services'
import {catchError, distinctUntilChanged, first, map, skip, switchMap, tap,} from 'rxjs/operators'
import {TranslatePipe} from "@ngx-translate/core";
import {BehaviorSubject, EMPTY, forkJoin, Observable, of, Subject} from "rxjs";
import {DriversService} from "@atl/admin/drivers/services";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {IDriver} from '@atl/admin/drivers/interfaces';
import {isEqual} from "lodash";
import {ActivatedRoute} from "@angular/router";
import {UrlService} from "@atl/shared/services";
import {AsideLayoutComponent} from '@app/@atl/modules/layouts/aside-layout/aside-layout.component';
import {HeaderLayoutService} from '@app/@atl/modules/layouts/header-layout/header-layout.service';
import {FormControl} from "@angular/forms";
import {ModelsService} from "@atl/admin/models/services";
import {ModalService} from "@atl/shared/services/modal.service";
import {DeleteDialogComponent} from "@atl/modules/modals/delete-dialog/delete-dialog.component";
import {
    GetChildrenFn,
    ILtaNodeData,
    ILtaTreeOptions,
    TreeCheckboxType,
    TreeMode
} from "@atl/modules/tree/interfaces/tree.interface";
import {LtaTreeComponent} from "@atl/modules/tree/tree.component";
import {IDType} from "@ali-hm/angular-tree-component/lib/defs/api";
import {UsersService} from '@app/@atl/administration/users/services';
import {
    BtnState,
    getValue,
    ICreateObjectByModel,
    ICreateObjectByModelExtra,
    IObject,
    IObjectPathItem,
    IUpdateObject,
    IUser,
    LtaSelectOptionModel,
    Types,
    TypesUtils,
    ValueWebsocket,
    WebsocketService
} from '@atl/lacerta-ui-common';

export const OBJECT_ID_QUERY_PARAM = 'id'
const ROOT_OBJECT_ID = 1

@UntilDestroy()
@Component({
    selector: 'lta-objects-page',
    templateUrl: 'objects-page.component.html',
    styleUrls: ['objects-page.component.scss'],
    providers: [
        ObjectsService,
        ModelsService,
        DriversService,
        EventTypesService
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectsPageComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('tree') public tree: LtaTreeComponent;
    @ViewChild('deleteDialogContent') deleteDialogContent: TemplateRef<any>;
    public addObjectToFormula$: Subject<number> = new Subject<number>()
    public drivers$: Observable<IDriver[]> = this.driversService.drivers$;
    public driversSelectOptions$: Observable<LtaSelectOptionModel<number>[]> = this.driversService.drivers$
        .pipe(map(value => value.map(value => {
            return {
                name: value.name + ':' + value.driver_info.type_name,
                value: value.id
            }
        })))
    public eventTypesSelectOptions$: Observable<LtaSelectOptionModel[]> = this.eventTypesService.eventTypes$
        .pipe(map(value => value.map(value => {
            return {
                name: value.name,
                value: value.id
            }
        })))
    public isLoading$: Observable<boolean> = this.headerLayoutService.isLoading$
    public modelsSelectOptions$: Observable<LtaSelectOptionModel[]> = this.modelsService.models$.pipe(
        map(models => {
            return models.map((m) => ({
                name: m.name,
                value: m.id,
                icon: TypesUtils.getIconByModelId(m.id),
            }))
        }))
    public saveState: BtnState = 'SAVE'
    public showObjectSelectForFormula$ = new BehaviorSubject<boolean>(false)
    public websocketValue$: Observable<ValueWebsocket>;
    public treeFilter: string = '';
    public activeObjectSiblings$: Observable<IObject[]> = this.objectsService.activeObject$.pipe(
        switchMap(object => {
            if (!object || !object.id && !object.parent_id) {
                return of([])
            }
            if (object.parent_id === this.objectsService.ROOT_NODE_ID) {
                return this.objectsService.getRoot().pipe(map(objects => objects.filter(o => o.id !== object.id)))
            } else {
                return this.objectsService.objectsChildren$
                    .pipe(first(),
                        switchMap(childrenMap => {
                            const siblings = childrenMap.get(object.parent_id)?.filter(ch => ch.id != object.id)
                            if (siblings) {
                                return of(siblings)
                            } else if (object.parent_id) {
                                return this.objectsService.getObjectChildren(object.parent_id, true)
                                    .pipe(
                                        map(value => value.filter(ch => ch.id != object.id))
                                    )
                            } else {
                                return of([] as IObject[])
                            }
                        })
                    )
            }
        })
    )
    public searchResult = []
    public forceDelete = new FormControl(false);
    public isFullScreenCalculatorMode = false
    public searchError$ = new Subject<Error>()
    public currentCreator: IUser
    @ViewChild(AsideLayoutComponent) private panel: AsideLayoutComponent;
    public activeObject$: Observable<IObject> = this.objectsService.activeObject$
        .pipe(
            tap(x => {
                if (x) {
                    this.subscribeToWebsocketValue(x.id)
                    this.panel.openPanel()
                } else {
                    this.tree?.resetActive()
                    this.panel?.hidePanel()
                }
            })
        )
    private unsubscribe: { unsubscribe: Function }[] = []

    private expandedNodesCache = [];

    constructor(
        private objectsService: ObjectsService,
        private modelsService: ModelsService,
        private eventTypesService: EventTypesService,
        private translate: TranslatePipe,
        private headerLayoutService: HeaderLayoutService,
        private driversService: DriversService,
        private websocketService: WebsocketService,
        private route: ActivatedRoute,
        private urlService: UrlService,
        private cd: ChangeDetectorRef,
        private modalService: ModalService,
        private usersService: UsersService
    ) {
    }

    public canCreateObject(activeObject: IObject) {
        return activeObject ? !activeObject.id : false;
    }

    public ngAfterViewInit() {
        this.tree.activeID$.pipe(untilDestroyed(this), skip(1)).subscribe(selected => {
            if (selected) {
                this.selectObject(selected)
            } else {
                this.selectObject(null)
            }
        })

        this.tree.selectedIDs$.pipe(untilDestroyed(this), skip(1)).subscribe(selected => {
            if (this.showObjectSelectForFormula$.value) {
                this.addObjectToFormula(selected)
            }
        })

        this.tree.onLoadChildren.subscribe(id => this.expandedNodesCache.push(id));
    }

    public ngOnInit(): void {
        this.headerLayoutService.setLoading(true)
        forkJoin([
            this.modelsService.getAllModels(),
            this.eventTypesService.getEventTypes(),
            this.driversService.getDrivers(),
        ]).pipe(untilDestroyed(this))
            .subscribe({
                next: () => this.headerLayoutService.setLoading(false),
                error: () => this.headerLayoutService.setLoading(false)
            })

        this.getRootTree()

        this.route.queryParamMap
            .pipe(
                untilDestroyed(this),
                map(value => Number(value.get(OBJECT_ID_QUERY_PARAM))),
                distinctUntilChanged((x, y) => isEqual(x, y)),
                switchMap(id => {
                    if (id) {
                        this.headerLayoutService.setLoading(true)
                        return forkJoin([
                            this.objectsService.getObjectById(id)
                                .pipe(
                                    untilDestroyed(this),
                                    catchError(() => {
                                        this.selectObject(null)
                                        this.headerLayoutService.setLoading(false)
                                        return EMPTY
                                    })),
                            this.objectsService.getObjectPath(id, true)
                                .pipe(
                                    untilDestroyed(this),
                                    map(value => value.slice(0, -1)),
                                ),])
                    } else {
                        return of([null, null])
                    }
                }),
                switchMap(([object, path]) => {
                    if (object) {
                        return forkJoin([of(object), of(path), object.user_id ? this.usersService.getUserById(object.user_id) : of(null)])
                    } else {
                        return of([null, null, null])
                    }
                })
            )
            .subscribe(([object, path, creator]: [IObject, IObjectPathItem[], IUser]) => {
                this.currentCreator = creator
                if (!object) {
                    return
                }
                this.objectsService.setActiveObject(object)
                if (object) {
                    if (this.saveState !== 'CREATED') {
                        this.saveState = 'SAVE'
                    }
                    this.tree.setActive(object.id)
                    this.tree.expandNodes(path.map(obj => obj.id))
                } else {
                    this.tree.resetActive()
                }
                this.headerLayoutService.setLoading(false)
            })
    }

    public canRestore(type: Types) {
        if (!type) return false
        return TypesUtils.getIconByModelId(type) === 'composite' || TypesUtils.getIconByModelId(type) === 'folder'
    }

    public isCompositeRestore(type: Types): boolean {
        if (!type) return false
        return TypesUtils.getIconByModelId(type) === 'composite'
    }

    public getChildren: GetChildrenFn = (item: IObject) => {
        return this.objectsService.getObjectChildren(item.id)
            .pipe(map(children => {
                return children.sort((a, b) => ObjectsService.sort(a, b)).map(obj => ObjectsService.toTreeItem(obj));
            }));
    }

    public treeOptions$: Observable<ILtaTreeOptions> = this.showObjectSelectForFormula$.pipe(distinctUntilChanged(), map(status => {
        return {
            mode: status ? TreeMode.MultiSelect : TreeMode.SingleSelect,
            theme: 'object-tree',
            checkboxType: TreeCheckboxType.text,
            selectAction: status ? (node) => this.addObjectToFormula(node.id) : null,
            addItemBtnOptions: {
                text: this.translate.transform('lta.addObject'),
                callback: (parent: ILtaNodeData) => {
                    this.addObject(parent.item)
                }
            },
            sortFn: ObjectsService.sort,
            filterFn: this.filter,
            getChildrenFn: this.getChildren
        } as ILtaTreeOptions
    }))

    public createDeleteDialog(activeObject: IObject) {
        const dialogRef = this.modalService.create<DeleteDialogComponent>(activeObject.id.toString(), DeleteDialogComponent, {
            inputs: {deleteBtnText: 'lta.delete'}, content: this.deleteDialogContent, contentContext: activeObject
        })
        dialogRef.instance.onDelete.pipe(untilDestroyed(dialogRef.instance)).subscribe(() => {
            dialogRef.instance.isLoading = true
            this.objectsService.deleteObject(activeObject, {
                forceData: this.forceDelete.value ?? false,
                forceMeta: this.forceDelete.value ?? false,
            })
                .pipe(
                    catchError((err, caught) => {
                        dialogRef.instance.isLoading = false
                        throw err
                    })
                )
                .subscribe(() => {
                    this.tree.deleteNode(this.tree.getNodeById(activeObject.id))
                    this.modalService.remove(activeObject.id.toString())
                    this.forceDelete.reset()
                    this.urlService.addQueryParams({
                        [OBJECT_ID_QUERY_PARAM]: null,
                    })

                    if (activeObject.parent_id === ROOT_OBJECT_ID) return
                    this.objectsService.getObjectById(activeObject.parent_id).subscribe(parent => {
                        this.tree.updateNode(ObjectsService.toTreeItem(parent))
                        if (parent.id !== ROOT_OBJECT_ID) {
                            this.updateParentTree(parent.parent_id)
                        }
                    })
                });
        })
        dialogRef.instance.onCancel.pipe(untilDestroyed(dialogRef.instance)).subscribe(() => {
            this.modalService.remove(activeObject.id.toString())
            this.forceDelete.reset()
        })
    }

    public selectObject(id: IDType) {
        const isAddNewObject = id !== null && isNaN(Number(id))
        this.isFullScreenCalculatorMode = false
        if (id && !isAddNewObject) {
            this.urlService.addQueryParams({
                [OBJECT_ID_QUERY_PARAM]: id,
            })
        } else {
            if (!isAddNewObject && this.route.snapshot.queryParamMap.get(OBJECT_ID_QUERY_PARAM)) {
                this.objectsService.setActiveObject(null)
            }
            this.urlService.addQueryParams({
                [OBJECT_ID_QUERY_PARAM]: null,
            })
        }
    }

    public addObject(parent: IObject = null) {
        if (!parent) this.tree.resetActive()
        this.saveState = 'CREATE'
        this.objectsService.addBlankObject(parent);
    }

    public createObject($event: {
        object: ICreateObjectByModel,
        extra: ICreateObjectByModelExtra
    }) {
        this.headerLayoutService.setLoading(true);
        this.isFullScreenCalculatorMode = false
        this.saveState = 'CREATING'
        this.objectsService.createObject($event.object, $event.extra).subscribe({
            next: (value) => {
                this.saveState = 'CREATED'
                if (value.parent_id === ROOT_OBJECT_ID) {
                    this.tree.addNode(ObjectsService.toTreeItem(value))
                    this.getRootTree()
                } else {
                    this.objectsService.getObjectById(value.parent_id).subscribe(parent => {
                        this.tree.updateNode(ObjectsService.toTreeItem(parent))
                        this.tree.addNode(ObjectsService.toTreeItem(value), this.tree.getNodeById(value.parent_id))
                        if (parent.id !== ROOT_OBJECT_ID) {
                            this.updateParentTree(parent.parent_id)
                        }
                    })
                }
                this.tree.resetFocus()
                this.tree.setActive(value.id)
                this.headerLayoutService.setLoading(false);
            },
            error: (error) => {
                this.saveState = 'CREATE'
                this.cd.markForCheck()
                this.headerLayoutService.setLoading(false);
            }
        })
    }

    public updateObject({object, id}: { object: IUpdateObject, id: number }) {
        this.isFullScreenCalculatorMode = false
        this.saveState = 'SAVING'
        this.objectsService.updateObject(id, object)
            .subscribe({
                next: (value) => {
                    this.saveState = 'SAVED'
                    this.tree.updateNode(ObjectsService.toTreeItem(value))
                    if (value.parent_id !== ROOT_OBJECT_ID) {
                        this.updateParentTree(value.parent_id)
                    }
                },
                error: (error) => {
                    this.saveState = 'SAVE'
                    this.cd.markForCheck()
                }
            })
    }

    public onCancel() {
        this.objectsService.setActiveObject(null)
        this.selectObject(null)
    }

    public addObjectToFormula(id) {
        this.addObjectToFormula$.next(id)
        //prevent same object skipping
        setTimeout(() => {
            this.addObjectToFormula$.next(null)
        })
    }

    public onSearch(string: string) {
        this.tree.resetActive()
        this.tree.resetFocus()
        if (!string) {
            this.treeFilter = ''
            this.searchResult = []
            let focusedNodeId = this.tree.state.focusedNodeId
            if (focusedNodeId) {
                this.objectsService.getObjectPath(Number(focusedNodeId), true).subscribe((toExpand) => {
                    let objIds = toExpand.map(obj => obj.id)
                    objIds.splice(objIds.length - 1, 1)
                    setTimeout(() => {
                        this.tree.expandNodes(objIds)
                        this.tree.setFocus(focusedNodeId)
                    });


                })
            }
            return
        }
        this.onCancel()
        this.headerLayoutService.setLoading(true)

        this.objectsService.searchForObject(string)
            .subscribe({
                next: ({all, toExpand}) => {
                    this.searchResult = all

                    this.tree.expandNodes(toExpand, true)

                    if (this.searchResult.length) {
                        this.tree.setFocus(this.searchResult[0])
                    }

                    const fromCache = toExpand.every(id => this.expandedNodesCache.includes(id));
                    if (fromCache) {
                        setTimeout(() => {
                            this.treeFilter = string.toLowerCase()
                            this.headerLayoutService.setLoading(false)
                        })
                    } else {
                        const sub = this.tree.onLoadChildren.subscribe({
                            next: () => {
                                this.treeFilter = string.toLowerCase()
                                this.headerLayoutService.setLoading(false)
                                sub.unsubscribe();
                            }
                        })
                        this.expandedNodesCache.push(...toExpand)
                    }
                },
                error: err => {
                    this.searchError$.next(err)
                    this.headerLayoutService.setLoading(false)
                }
            })
    }

    public restoreObject(obj: IObject) {
        this.objectsService.restoreObject(obj)
            .pipe(
                switchMap(value => forkJoin([of(value), this.objectsService.getObjectChildren(obj.id, true).pipe(map(value => value.sort(ObjectsService.sort)))])),
            )
            .subscribe(([obj, children]) => {
                if (obj.model.name === 'Directory') return
                this.tree.updateNode(ObjectsService.toTreeItem(obj, {
                    children: children.map(obj => ObjectsService.toTreeItem(obj,)),
                    expanded: false
                }))
                if (obj.parent_id !== ROOT_OBJECT_ID) {
                    this.updateParentTree(obj.parent_id)
                }
            })
    }

    ngOnDestroy() {
        this.unsubscribe.forEach(f => f.unsubscribe())
        this.activeObject$.pipe(first()).subscribe(value => {
            value?.id && this.modalService.remove(value?.id.toString())
        })

    }

    public toggleFullScreen(isFullScreen: boolean) {
        this.isFullScreenCalculatorMode = isFullScreen
    }

    private getRootTree() {
        this.headerLayoutService.setLoading(true)

        this.objectsService.getRoot().pipe(
            map(root => root.sort((a, b) => ObjectsService.sort(a, b))),
            map(obj => obj.map(o => ObjectsService.toTreeItem(o))),
            tap(() => {
                this.headerLayoutService.setLoading(false)
            })
        ).subscribe((root: ILtaNodeData[]) => {
            this.tree.nodes = root
        })
    }

    private updateParentTree(parentId: number, rootObjectId: number = 1) {
        if (parentId === rootObjectId) return
        this.objectsService.getObjectById(parentId).subscribe(parent => {
            if (parent.id !== rootObjectId) {
                this.updateParentTree(parent.parent_id)
            }
            this.tree.updateNode(ObjectsService.toTreeItem(parent))
        })
    }

    private subscribeToWebsocketValue(id: number) {
        this.unsubscribe.forEach(f => f.unsubscribe())
        this.unsubscribe = []

        if (!id) return

        this.websocketService.waitForConnection(() => {
            const [values$, unsubscribeFunction] = this.websocketService.subscribeToValues([id], true)
            this.unsubscribe.push({unsubscribe: unsubscribeFunction})
            this.websocketValue$ = values$
                .pipe(map(message => {
                    return {
                        value: getValue(message),
                        isReliable: !!message.status_code || false
                    }
                }))
        })

    }

    private filter(item: IObject, filterString: string): boolean {
        return item.name.toLowerCase().indexOf(filterString) !== -1 || item.descr.toLowerCase().indexOf(filterString) !== -1
    }
}
